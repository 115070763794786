import { lorax } from './urls';
import { isLoggedIn, login, setToken } from './sso';
import urlBase64Encode from '../../src/base/utilities/urlBase64Encode';

const unauthorized = async () => {
  if (await isLoggedIn()) {
    const currentRedirect = encodeURIComponent(window.location.href);
    window.location.href = `${lorax}/errors/unauthorized?redirect=${currentRedirect}`;
  } else {
    await setToken(null);
    await login();
  }
};

const handler = async (status, error) => {
  console.debug('[SSO] Error handler.');

  if (error && typeof error === 'object' && error.useErrorPage) {
    const encodedMessage = urlBase64Encode(error.message);
    let encodedInformation = '';
    if (error.additionalInformation) {
      encodedInformation = `/${urlBase64Encode(JSON.stringify(error.additionalInformation))}`;
    }

    const currentRedirect = encodeURIComponent(window.location.href);
    window.location.href = `${lorax}/errors/${error.errorCode}/${encodedMessage}${encodedInformation}?redirect=${currentRedirect}`;
    return true;
  }

  if (status === 401) {
    await setToken(null);
    await login();
    return true;
  }

  if (status === 403) {
    await unauthorized();
    return true;
  }

  return false;
};

export default {
  handler,
  unauthorized,
};
